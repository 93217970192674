

  import { defineComponent, onMounted, reactive, ref } from 'vue';
  import AuthorizePopup from '@/components/AuthorizePopup.vue';
  import RedeemPopup from '@/components/RedeemPopup.vue';
  import StakePopup from '@/components/StakePopup.vue';
  import Web3Provider from '../../utils/Web3Provider';
  import SvgIcon from '@/components/SvgIcon.vue';
  import { formatCurrency } from '@/utils/baseUtils';
  import BigNumber from 'bignumber.js';
  import { JKT_DECIMAL } from '@/const/address/tokenAddress';
  import LoadingOverlay from '@/components/LoadingOverlay.vue';
  import farmConfig from '@/const/config.js';
  import { Toast } from 'vant';

  export default defineComponent({
    name: 'deFi',
    components: { SvgIcon, AuthorizePopup, RedeemPopup, StakePopup, LoadingOverlay },
    setup() {
      const loading = ref(false);

      const farmlist = ref();
      farmlist.value = farmConfig.farmList;

      const state = reactive({
        redeemShow: false,
        authPopShow: false,
        stakePopShow: false,
        bitChecked: false,
        jktChecked: false,
      });

      const countData = reactive({
        earningCount: 'Loading...',
        eSelfHash: 'Loading...',
        eTeamHash: 'Loading...',
      });

      const jktInfo = reactive({
        allowance: '',
        contract: '',
      });

      const lpTokenList = ref();
      lpTokenList.value = [];

      const list = ref();
      list.value = [];

      const farmLiData = ref();
      farmLiData.value = {};

      function handleRedeem(index: number) {
        state.redeemShow = true;
        farmLiData.value = farmlist.value[index];
      }

      async function handleStake(index: number) {
        lpTokenList.value = [];
        if (farmlist.value[index].authTypes.length === 2) {
          lpTokenList.value.push(farmlist.value[index], {
            lpTokenInfo: {
              allowance: jktInfo.allowance,
              contract: jktInfo.contract,
            },
          });
        } else {
          lpTokenList.value.push(farmlist.value[index]);
        }

        if (jktInfo.allowance === '0' || farmlist.value[index].lpTokenInfo.allowance === '0') {
          state.authPopShow = true;
        } else {
          state.stakePopShow = true;
        }
      }

      function handleAuthClose() {
        state.authPopShow = false;
      }

      function handleRedeemClose() {
        state.redeemShow = false;
      }

      function handleStakeClose() {
        state.stakePopShow = false;
      }

      function handleAuthDone() {
        state.authPopShow = false;
        state.stakePopShow = true;
      }

      async function getEarningCount() {
        const [defiEarning, decimal] = await Promise.all([Web3Provider.getInstance().getDefiEarning(), Web3Provider.getInstance().getJKTDecimals()]);
        countData.earningCount = formatCurrency(new BigNumber(defiEarning).div(new BigNumber(10).pow(decimal)), '', 10);
      }

      async function toClaimed() {
        loading.value = true;
        const claimedStatus = await Web3Provider.getInstance().withdrawFarmReward();
        if (claimedStatus) {
          loading.value = false;
          await getEarningCount();
          Toast.success('Claimed success!');
        } else {
          loading.value = false;
          Toast.fail('Claimed failed!');
        }
      }

      async function getUserInfo() {
        const [res, decimal] = await Promise.all([Web3Provider.getInstance().getUserInfo(), Web3Provider.getInstance().getJKTDecimals()]);
        if (res) {
          countData.eTeamHash = String(new BigNumber(res.eTeamHash).div(new BigNumber(10).pow(decimal)).toFixed(2));
          countData.eSelfHash = String(new BigNumber(res.eSelfHash).div(new BigNumber(10).pow(decimal)).toFixed(2));
        }
      }

      async function getFarmList() {
        for (let i = 0; i < farmlist.value.length; i += 1) {
          const lpTokenAddress = farmlist.value[i].lpToken;
          const contract = await Web3Provider.getInstance().createLpTokenContract(lpTokenAddress);
          const lpInfo = await Web3Provider.getInstance().getStakePoolInfo(lpTokenAddress);
          const allowance = await Web3Provider.getInstance().checkAllowance(contract);
          const farmApy = new BigNumber(lpInfo.apy).div(new BigNumber(10).pow(JKT_DECIMAL)).times(100).toFixed(2);
          const jktStaked = formatCurrency(new BigNumber(lpInfo.jktStaked).div(new BigNumber(10).pow(JKT_DECIMAL)), '', 3);
          const lpTokenStaked = formatCurrency(new BigNumber(lpInfo.lpTokenStaked).div(new BigNumber(10).pow(farmlist.value[i].decimal)), '', 3);
          const power = new BigNumber(lpInfo.power).div(new BigNumber(10).pow(JKT_DECIMAL)).toFixed(2);

          const stakeInfoArray: any = [];
          if (farmlist.value[i].authTypes.length === 2) {
            stakeInfoArray.push(jktStaked, lpTokenStaked, power);
          } else {
            stakeInfoArray.push(lpTokenStaked, power);
          }

          farmlist.value[i].lpTokenInfo = {
            allowance,
            lpTokenAddress,
            contract,
            farmName: farmlist.value[i].farmSymbol,
            farmApy: farmApy,
            stakedInfo: stakeInfoArray,
            getPower: lpInfo.getPower,
            serviceCharge: lpInfo.serviceCharge,
          };
        }
      }

      async function getJktAllowance() {
        jktInfo.contract = await Web3Provider.getInstance().getJKTContract();
        jktInfo.allowance = await Web3Provider.getInstance().checkAllowance(jktInfo.contract);
      }

      function handleApprove(index: number) {
        const targetItem = farmlist.value.find((item) => item.lpTokenInfo.contract === lpTokenList.value[index].lpTokenInfo.contract);
        if (!lpTokenList.value[index].farmSymbol) {
          targetItem.lpTokenInfo.allowance = '1';
          jktInfo.allowance = '1';
        } else {
          targetItem.lpTokenInfo.allowance = '1';
        }
      }

      function refreshDeFiData() {
        getEarningCount();
        getUserInfo();
        getFarmList();
      }

      onMounted(() => {
        getEarningCount();
        getUserInfo();
        getFarmList();
        getJktAllowance();
      });

      return {
        loading,
        state,
        farmlist,
        countData,
        list,
        lpTokenList,
        farmLiData,
        handleRedeem,
        handleAuthClose,
        handleStake,
        handleRedeemClose,
        handleStakeClose,
        handleAuthDone,
        toClaimed,
        handleApprove,
        refreshDeFiData,
      };
    },
  });
