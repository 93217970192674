
import { defineComponent, reactive, ref, watchEffect } from 'vue';
import Web3Provider from '@/utils/Web3Provider';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import { Toast } from 'vant';

export default defineComponent({
  name: 'authorizePopup',
  components: { LoadingOverlay },
  props: { authShow: Boolean, lpTokenList: Array },
  emits: ['authPopClose', 'authDone', 'gottenApprove'],
  setup(props, context) {
    const loading = ref(false);

    const state = reactive({
      authPopShow: false,
    });

    const authList = ref();
    authList.value = [];

    function handleClose() {
      context.emit('authPopClose');
    }

    function onAuthDone() {
      let authStatus = 0;
      authList.value.forEach((item: any) => {
        if (item.allowance !== '0') {
          authStatus += 1;
        }
      });

      if (authStatus === authList.value.length) {
        context.emit('authDone');
      } else {
        Toast.fail('Please complete authorization!');
      }
    }

    async function getApprove(index: number, token: string) {
      loading.value = true;

      const res = await Web3Provider.getInstance().getApprove(token);
      if (!res) {
        authList.value[index].allowance = '0';
        authList.value[index].active = false;
        loading.value = false;
      } else {
        context.emit('gottenApprove', index);
        loading.value = false;
      }
    }



    watchEffect(() => {
      state.authPopShow = props.authShow || false;
      authList.value = [];
      if (props.lpTokenList) {
        props.lpTokenList.forEach((item: any) => {
          authList.value.push({...item, ...{active: item.lpTokenInfo.allowance !== '0'}});
        })
      }
    });

    return { state, authList, handleClose, onAuthDone, getApprove, loading};
  },
});
